import { getRequestTime } from './datetime';
import md5 from 'crypto-js/md5';

const URL_BACKEND_API = "https://twingenio-backend-staging.azurewebsites.net/";

export function twoDigits(v) {
    return ("0" + v).slice(-2);
}

export function _exePostRequest(path, data, onSuccess, onError, signatureKey = null) {
    const xmlhttp = new XMLHttpRequest();
    const formData = new FormData();

    Object.keys(data).forEach(k => formData.append(k, data[k]));
    
    let url = URL_BACKEND_API + path;

    xmlhttp.onload = () => {
        const status = xmlhttp.status;
        let response;

        if (status === 200) {
            response = JSON.parse(xmlhttp.responseText);
            onSuccess(response);
        } else {
            try {
                response = JSON.parse(xmlhttp.responseText);
            } catch (e) {
                response = "UNKNOW";
            }

            onError(response);
        }
    };

    xmlhttp.onerror = () => onError(`Connection error.`);
    xmlhttp.open("POST", url, true);

    if (signatureKey) {
        let toSign = "";

        [
            "session_id",
            "time",
            "path",
            "params",
        ].forEach(k => toSign += data[k]);

        // console.log(`data '${data}'`);
        // console.log(`toSign '${toSign}'`);
        // console.log(`signatureKey '${signatureKey}'`);

        formData.append(
            "signature",
            md5(toSign + signatureKey)
        );
    }

    xmlhttp.send(formData);

    return xmlhttp;
}