const pwdWrappers = [...document.querySelectorAll('.pwd-wrapper')];

pwdWrappers.forEach((wrapper) => {
    const button = wrapper.querySelector('.hide-pwd');
    const inputField = wrapper.querySelector('input[type="password"]');
    const icon = button.querySelector('.material-symbols-rounded');

    button.addEventListener('click', (e) => {
        if (inputField.type === 'password') {
            inputField.type = 'text';
            button.setAttribute('aria-label', 'Nascondi password');
            icon.textContent = 'visibility_off';
        } else {
            inputField.type = 'password';
            button.setAttribute('aria-label', 'Mostra password');
            icon.textContent = 'visibility';
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const fileInput = document.getElementById('upload-avatar');
    const userImage = document.getElementById('user-image');
    const avatar = document.getElementById('avatar');

    if (fileInput) {
        fileInput.addEventListener('change', function(event) {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();
                
                reader.onload = function(e) {
                    userImage.src = e.target.result;
                    userImage.classList.remove('hidden');
                    avatar.classList.add('hidden');
                }
                
                reader.readAsDataURL(file);
            } else {
                userImage.classList.add('hidden');
                avatar.classList.remove('hidden');
            }
        });
    }
});