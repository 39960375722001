import { twoDigits } from './format';

export function stampNow(len = 14) {
    const date = new Date();
    let dateParts = [
        date.getUTCFullYear(),
        twoDigits(date.getUTCMonth() + 1),
        twoDigits(date.getUTCDate()),
        twoDigits(date.getUTCHours()),
        twoDigits(date.getUTCMinutes()),
        twoDigits(date.getUTCSeconds())
    ];
    
    return (
        dateParts.join('')
    ).substring(0, len);
}

export function getRequestTime() {
    const now = new Date();

    // return now.toISOString().slice(0, -5);
    return now.toISOString().slice(0, 19);
}