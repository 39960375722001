import { _exePostRequest } from './helpers/format';
import { getRequestTime } from './helpers/datetime';
import * as cookie from './helpers/cookie';

// const session = {
//     sessionId: null,
//     signatureKey: null,
//     user: null,
// };

export function request (path, params, onSuccess, onError) {
    _exePostRequest(
        path,
        {
            time: getRequestTime(),
            path: path,
            session_id: cookie.get('session_id'),
            params: JSON.stringify(params ? params : {}),
        },
        onSuccess,
        onError,
        cookie.get('signature_key')
    );
}


// function request (path, params, onSuccess, onError) {
//     const data = {
//         time: new Date().toISOString().slice(0, 19),
//         path: path,
//         session_id: session.sessionId,
//         params: JSON.stringify(params ? params : {}),
//     };
//     const formData = new FormData();

//     Object.keys(data).forEach(k => formData.append(k, data[k]));

//     let url = URL_BACKEND_API + path;
//     const xmlhttp = new XMLHttpRequest();

//     xmlhttp.onload = () => {
//         const status = xmlhttp.status;
//         let response;

//         if (status === 200) {
//             response = JSON.parse(xmlhttp.responseText);
//             onSuccess(response);
//         } else {
//             try {
//                 response = JSON.parse(xmlhttp.responseText);
//             } catch (e) {
//                 response = "UNKNOW";
//             }
//             onError(response);
//         }
//     };

//     xmlhttp.onerror = () => onError(`Connection error.`);
//     xmlhttp.open("POST", url, true);

//     const signatureKey = session.signatureKey;

//     if (signatureKey) {
//         let toSign = "";

//         [
//             "session_id",
//             "time",
//             "path",
//             "params",
//         ].forEach(k => toSign += data[k]);

//         formData.append(
//             "signature",
//             md5(toSign + signatureKey)
//         );
//     }
//     xmlhttp.send(formData);

//     return xmlhttp;
// }