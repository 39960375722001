import { request } from '../api-client';

document.addEventListener("DOMContentLoaded", () => {
    const changePwdForm = document.getElementById('change-password-form');

    changePwdForm?.addEventListener('submit', (event) => {
        event.preventDefault();

        let submitBtn = event.target.querySelector('[type="submit"]')
        let currentPwd = event.target.querySelector('[name="current-password"]').value
        let newPwd = event.target.querySelector('[name="new-password"]').value;
        let newPwdRepeated = event.target.querySelector('[name="confirm-password"]').value;

        submitBtn.disabled = true;

        if ('' == currentPwd 
            || '' == newPwd 
            || '' == newPwdRepeated) {
            toast.danger(translations.requiredFields, {
                gravity: 'bottom',
                position: 'right',
                duration: 5000
            });

            submitBtn.disabled = false;
        } else if ('' != currentPwd 
                   && '' != newPwd 
                   && '' != newPwdRepeated 
                   && (newPwd != newPwdRepeated)) {
            toast.danger(translations.passwordsMismatch, {
                gravity: 'bottom',
                position: 'right',
                duration: 5000
            });

            submitBtn.disabled = false;
        } else {
            request(
                '/user/change_password', 
                {
                    current_password: currentPwd,
                    new_password: newPwd,
                    new_password_repeated: newPwdRepeated,
                }, 
                (response) => {
                    console.log(response);

                    toast.success(translations.successMessage, {
                        gravity: 'bottom',
                        position: 'right',
                    })
    
                    submitBtn.disabled = false;
                },
                (response) => {
                    console.log(response);
                    let error_message = null == response.data.error_message ? response.data.error_code : response.data.error_message;

                    toast.danger(translations.errorMessage + error_message, {
                        gravity: 'bottom',
                        position: 'right',
                        duration: 5000
                    })

                    submitBtn.disabled = false;
                }
            );
        }
    });
});